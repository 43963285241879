import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import styled from "styled-components";
import tw from "twin.macro";

import Layout from '@layouts/index';
import SEO from '@components/seo';

const Terms = tw.div`mb-60 px-20 lg:mb-100 lg:p-0`;

const Header = tw.div`
  mb-20 text-xl leading-33 -tracking-016
  md:max-w-250 md:-tracking-028
  lg:text-2xl lg:leading-60 lg:max-w-399 lg:mt-60 lg:mr-120 lg:mb-40
  xl:text-4xl xl:max-w-475 xl:mr-45
`;

const Subheader = tw.div`font-pangramBold mt-40 mb-15 text-xl`;

const Point = styled.div`
  b {
    ${tw`font-pangramBold mr-6`}
  }
`;

const MainPoint = styled(Point)`
  ${tw`mt-18 mb-8`}
  
  b {
    ${tw`mr-0`}
  }
`;

const TermsPage = () => {
  const intl = useIntl();

  return (
    <Layout>
      <SEO
        title={intl.formatMessage({ id: 'SEO.Title.Offer' })}
        description={intl.formatMessage({ id: 'SEO.Description.Offer' })}
      />
      <Terms>
        <Header>Regulamin</Header>
        <MainPoint><b>1. Definicje </b></MainPoint>
        <Point><b>a.</b> Ilekroć w niniejszym dokumencie jest mowa o:</Point>
        <Point><b>I.</b> Regulaminie – rozumie się przez to niniejszy Regulamin korzystania z serwisu i usług Dajemyslowo.com,</Point>
        <Point><b>II.</b> Serwisie – rozumie się przez to Serwis Dajemyslowo.com,</Point>
        <Point><b>III.</b> Kliencie – rozumie się przez to osobę fizyczną, osobę prawną lub jednostkę organizacyjną nie posiadającą osobowości prawnej, która zdecyduje się korzystać z usług Operatora i wyśle zapytanie przez formularz zamawiania dostępny w serwisie,</Point>
        <Point><b>IV.</b> Operatorze – rozumie się przez to firmę PushUp Marta Sulikowska, ul. Ofiar Oświęcimskich 17, 50-069 Wrocław, posługującą się numerem NIP: 688-125-76-23.</Point>
        <MainPoint><b>2. Postanowienia ogólne</b></MainPoint>
        <Point><b>a.</b> Niniejszy Regulamin określa warunki świadczenia usług za pomocą Serwisu.</Point>
        <Point><b>b.</b> Zamawiając usługę Klient potwierdza, że zapoznał się z niniejszym Regulaminem i akceptuje warunki w nim zawarte, a także zobowiązuje się do ich przestrzegania.</Point>
        <Point><b>c.</b> Operator zastrzega sobie prawo do jednostronnego wprowadzania zmian i modyfikacji w niniejszym Regulaminie według swojego uznania. Użytkownicy zostaną poinformowani o tych zmianach za pośrednictwem newslettera.</Point>
        <MainPoint><b>3. Usługi świadczone za pośrednictwem witryny internetowej</b></MainPoint>
        <Point><b>a.</b> Serwis umożliwia Klientowi zamówienie dedykowanego, autorskiego produktu z zakresu content marketingu.</Point>
        <Point><b>b.</b> Operator zastrzega sobie prawo do zmiany Oferty bez podania przyczyn.</Point>
        <Point><b>c.</b> Operator jest zobowiązany dostarczyć zamówiony produkt w terminie uzgodnionym w trakcie ustaleń z Klientem. Operator każdorazowo wyznaczy potrzebną do realizacji zamówienia liczbę dni i powiadomi o tym Klienta.</Point>
        <Point><b>d.</b> Klient ma prawo do zgłoszenia uwag do dostarczonej mu przez Operatora pierwszej wersji treści, w terminie maksymalnie do 3 dni roboczych. Termin może zostać wydłużony w przypadku większych realizacji, w takim wypadku jest on ustalany indywidualnie między Operatorem i Klientem.</Point>
        <Point><b>e.</b> Operator ma obowiązek jednokrotnego, darmowego wprowadzenia wskazanych poprawek w ciągu 2 dni roboczych oraz odesłania Klientowi finalnej wersji treści.</Point>
        <Point><b>f.</b> Klient ma możliwość zgłoszenia uwag do finalnej wersji tekstu, lecz tzw. druga korekta jest płatna. Cena drugiej korekty jest ustalana indywidualnie.</Point>
        <Point><b>g.</b> Po dostarczeniu zamówionych treści, majątkowe prawa autorskie przenoszone są z Operatora na Klienta, następuje to wraz z dniem uznania konta Operatora kwotą stanowiącą wynagrodzenie Operatora za realizowane zlecenie. Jednocześnie Klient zyskuje wyłączne prawo do wykorzystywania otrzymanego produktu, a Operator zobowiązuje się do nieudostępniania oraz nie sprzedawania danej treści osobom trzecim.</Point>
        <Point><b>h.</b> Operator zastrzega sobie możliwość stosowania cen promocyjnych w wybranych przez siebie okresach i na określonych przez siebie zasadach.</Point>
        <Point><b>i.</b> Opłata za zrealizowane zlecenia płatna jest na podstawie faktury vat wystawianej po dostarczeniu Klientowi ostatecznej wersji zamawianych treści lub po upłynięciu terminu zgłoszenia uwag do otrzymanych treści.</Point>
        <Point><b>j.</b> Do kwoty wynagrodzenia określonego w pkt. “i” zostanie doliczony podatek VAT zgodnie z obowiązującymi przepisami. W przypadku zmiany stawki podatku VAT nastąpi odpowiednia zmiana kwoty wynagrodzenia brutto.</Point>
        <Point><b>k.</b> Klient wyraża zgodę na rozpoczęcie świadczenia usług przez Operatora przed upływem dziesięciodniowego terminu licząc od dnia zawarcia umowy, o którym mowa w art. 10 ust. 1 ustawy z dnia 2 marca 2000 r. o ochronie niektórych praw konsumentów oraz o odpowiedzialności za szkodę wyrządzoną przez produkt niebezpieczny (Dz. U. nr 22, poz. 271 – ze zm.). Wobec powyższego Klientowi nie przysługuje prawo do odstąpienia od umowy w trybie określonym w wyżej wymienionej Ustawie.</Point>
        <Point><b>l.</b> Klient nie ma prawa do odstąpienia od umowy w momencie uznania konta Operatora należną kwotą.</Point>
        <Point><b>m.</b> Klient wyraża zgodę na otrzymanie faktury drogą elektroniczną.</Point>
        <MainPoint><b>4. Odpowiedzialność</b></MainPoint>
        <Point>Operator nie ponosi żadnej odpowiedzialności za decyzje podjęte przez Klienta lub osoby trzecie na podstawie informacji umieszczonych w Serwisie.</Point>
        <Point><b>a.</b> Operator zastrzega sobie prawo do przerw lub zakłóceń w funkcjonowaniu Serwisu, jeżeli są one spowodowane: naprawą, konserwacją bądź modyfikacją sprzętu lub oprogramowania, lub przyczynami niezależnymi od Operatora Serwisu.</Point>
        <MainPoint><b>5. Postępowanie reklamacyjne</b></MainPoint>
        <Point><b>a.</b> Wszelkie reklamacje dotyczące korzystania z usług oferowanych za pośrednictwem Serwisu należy zgłaszać drogą elektroniczną na adres biuro@dajemyslowo.com</Point>
        <Point><b>b.</b> Rozpatrzenie reklamacji następuje w ciągu 5 dni roboczych licząc od dnia wpłynięcia do Operatora Serwisu e-maila dotyczącego reklamacji.</Point>
        <Point><b>c.</b> Odpowiedź w sprawie reklamacji jest wysyłana na adres e-mailowy Klienta.</Point>
        <MainPoint><b>6. Ochrona danych osobowych</b></MainPoint>
        <Point><b>a.</b> Warunkiem wysłania zapytania przez formularz zamawiania dostępny w serwisie jest podanie adresu e-mail Klienta oraz zaakceptowanie warunków niniejszego Regulaminu.</Point>
        <Point><b>b.</b> Dane podane w formularzu, nawet jeśli nie noszą znamion danych osobowych, nie są ujawniane przez Operatora Serwisu osobom trzecim.</Point>
        <MainPoint><b>7. Postanowienia końcowe </b></MainPoint>
        <Point><b>a.</b> Odpowiedzi na pisemne uwagi Klientów Serwisu dotyczące jego funkcjonowania udzielane są w terminie 7 dni od dnia otrzymania zapytania przez Operatora Serwisu.</Point>
        <Point><b>b.</b> Niniejszy Regulamin obowiązuje od dnia opublikowania na stronach Serwisu.</Point>

        <Subheader>Polityka prywatności</Subheader>
        <MainPoint>Polityka prywatności zawiera zasady postępowania z danymi osobowymi Użytkowników serwisu Dajemyslowo.com, przez Operatora – firmę PushUp Marta Sulikowska.</MainPoint>
        <Point><b>a.</b> Operatorem serwisu Dajemyslowo.com jest Firma PushUp Marta Sulikowska.</Point>
        <Point><b>b.</b> Klientem serwisu jest osoba, która skontaktowała się lub wysłała zapytanie przez formularze dostępne w Serwisie.</Point>
        <Point><b>c.</b> Operator nie ponosi odpowiedzialności za prawdziwość danych, którymi posługuje się kontaktujący się Klient.</Point>
        <Point><b>d.</b> Operator zbiera od Użytkowników, dane potrzebne do możliwości kontaktu z Klientem – adres e-mail, oraz dane potrzebne do wystawienia faktury za dostarczony produkt: imię, nazwisko, ulica, miasto, kod pocztowy, telefon, nip, nazwa firmy.</Point>
        <Point><b>e.</b> Operator serwisu Dajemyslowo.com nie udostępnia ani nie wykorzystuje w celach marketingowych danych przekazywanych przez Klientów.</Point>
        <Point><b>f.</b> W przypadku dodatkowych pytań dotyczących polityki prywatności, prosimy o kontakt e-mail: biuro@dajemyslowo.com</Point>
        <Point><b>g.</b> Niniejsza Polityka prywatności obowiązuje każdego Użytkownika serwisu. O wszelkich zmianach Użytkownicy zostaną poinformowani.</Point>

        <Subheader>Polityka Cookies</Subheader>
        <MainPoint>W celu dostosowania naszego serwisu do potrzeb użytkowników końcowych używamy danych zapisanych z wykorzystaniem plików tzw. cookies („ciasteczka”).</MainPoint>
        <MainPoint><b>Czym są pliki Cookies:</b></MainPoint>
        <Point>Pliki cookies - ciasteczka, to pliki zawierajace dane informatyczne przechowywane w urządzeniu końcowym użytkownika, korzystającego z naszego serwisu. Standardowo, pliki cookies zawierają nazwę strony internetowej, z której pochodzą, czas ich przechowywania na urządzeniu końcowym oraz losowo generowany numer identyfikujący. Oznacza to, że z chwilą rozpoczęcia korzystania przez użytkownika końcowego z serwisu internetowego, w logach systemowych urządzenia końcowego pojawia się w szczególności informacja o numerze IP, rodzaju urządzenia końcowego oraz czasie korzystania użytkownika końcowego.</Point>
        <MainPoint><b>W jakim celu wykorzystujemy pliki cookies:</b></MainPoint>
        <MainPoint><b>1.</b> Pliki cookies wykorzystywane są w celu</MainPoint>
        <Point><b>a.</b> dostosowania zawartości stron serwisu internetowego do preferencji użytkownika oraz optymalizacji korzystania ze stron internetowych; w szczególności pliki te pozwalają rozpoznać urządzenie użytkownika serwisu internetowego i odpowiednio wyświetlić stronę internetową, dostosowaną do jego indywidualnych potrzeb,</Point>
        <Point><b>b.</b> tworzenia statystyk, które pomagają zrozumieć, w jaki sposób użytkownicy serwisu korzystają ze stron internetowych, co umożliwia ulepszanie ich struktury i zawartości,</Point>
        <Point><b>c.</b> utrzymania sesji użytkownika serwisu internetowego (po zalogowaniu), dzięki której użytkownik nie musi na każdej podstronie serwisu ponownie wpisywać loginu i hasła,</Point>
        <Point><b>d.</b> dostarczania użytkownikom treści reklamowych bardziej dostosowanych do ich zainteresowań.</Point>
        <MainPoint><b>2.</b> W ramach serwisu Dajemyslowo.com używamy plików cookies w celach:</MainPoint>
        <Point><b>a.</b> statystycznych – badanie liczby użytkowników końcowych oraz sposobu w jaki korzystają z serwisu internetowego, w ceu ulepszenia ich struktury i zawartości,</Point>
        <Point><b>b.</b> indywidualizujących – dostosowanie zawartości i konfiguracji serwisu internetowego do indywidualnych potrzeb i preferencji użytkownika końcowego, optymalizacja zawartości w oparciu o dotychczasowe korzystanie z serwisu internetowego, w tymi w ramach rozpoznania urzadzenia użytkownika i dostosowania zawartości witryny do danego urządzenia</Point>
        <Point><b>c.</b> reklamowych - umożliwiające dostarczanie użytkownikom treści reklamowych bardziej dostosowanych do ich zainteresowań.</Point>
        <MainPoint><b>3.</b> Podkreślamy, że informacja przechowywana w urządzeniu końcowym oraz nasz dostęp do niej nie powoduje zmian konfiguracyjnych w tym urządzeniu oraz oprogramowaniu w nim zainstalowanym. W przeglądarce internetowej użytkownicy końcowi korzystający z naszego serwisu internetowego mogą zmienić ustawienia dotyczące plików tzw. cookies, co oznaczać będzie określenie warunków przechowywania lub uzyskiwania dostępu do powyższych informacji. Korzystanie z serwisu internetowego bez zmiany ustawienia plików tzw. cookies lub pozostawienie ustawień akceptujących ich umieszczanie i używanie oznacza wyrażenie zgody na ich używanie oraz zapisanie w pamięci urządzenia końcowego.</MainPoint>
        <MainPoint><b>4.</b> Wyłączenie stosowania cookies może spowodować utrudnienie korzystania z niektórych usług w ramach naszych serwisów internetowych, w szczególności wymagających logowania. Wyłączenie opcji przyjmowania cookies nie powoduje natomiast braku możliwości czytania lub oglądania treści zamieszczanych w serwisie Dajemyslowo.com.</MainPoint>
        <MainPoint><b>5.</b> Pliki cookies mogą być zamieszczane w urządzeniu końcowym użytkownika serwisu internetowego, a następnie wykorzystywane przez współpracujących z serwisem reklamodawców, firmy badawcze oraz dostawców aplikacji multimedialnych.</MainPoint>
      </Terms>
    </Layout>
  );
};

export default TermsPage;
